const projects = {
    "Jumeirah Golf Estates":['SANCTUARY FALLS "01"',
    'SANCTUARY FALLS "04"',
    'SANCTUARY FALLS "17"',
    'SANCTUARY FALLS "21"',
    'SANCTUARY FALLS "22"',
    'SANCTUARY FALLS "29"',
    'SANCTUARY FALLS "30A"',
    'SANCTUARY FALLS "32"',
    'SANCTUARY FALLS "37"',
    'SANCTUARY FALLS "56"',
    'SANCTUARY FALLS "67"',
    'SANCTUARY FALLS "74"',
    'SANCTUARY FALLS "75"',
    'SANCTUARY FALLS "81"',
    'SANCTUARY FALLS "85"',
    'FLAME TREE RIDGES D03',
    'FLAME TREE RIDGES D29',
    'LIME TREE VALLEY "23"'],

    "Palm Jumeirah":['FROND O-11',
        'FROND O-29',
        'FROND O-33',
        'FROND O-47',
        'FROND O-76',
        'FROND P-38',
        'FROND M-82',
        'FROND B-73',
        'FROND B-101',
        'FROND C-89',
        'FROND B-72',
        'FROND M-05',
        'FROND C-70',
        'FROND C-30',
        'FROND N 62 B',
        'FROND L-27',
        'FROND O-99'
        ],
    "Dubai Hills Estates": ['FREEWAY 137',
        'PARKWAY 07',
        'PARKWAY 21',
        'PARKWAY 29',
        'PARKWAY 33',
        'HILLS GROOVE 14',
        'GOLF PLACE 101',
        'GOLF PLACE 190',
        'GOLF PLACE 207',
        'GOLF PLACE 209',
        'GOLF PLACE 213',
        'GOLF PLACE 215',
        'GOLF PLACE 216',
        'GOLF PLACE 225'],
    "Meadows 5":['VILLA 14'],
    "MBR District 1":['VILLA 324',
        'VILLA 741',
        'VILLA 351'
        ],
    "Al Barari Estate":["VILLA 1601"],
    "Arabian Ranches":['HATTAN 90',
        'AL VORADA 2',
        'SAVANNA 25',
        'SAHEEL 5'
        ],
    "Maritime City":['WAREHOUSE 300A'],
    "Jumeirah Village Triangle":['VILLA 11'],
    "Jafza":['BENZ WAREHOUSE']
}
    
 
export default projects;